<template>

<app-page theme="purple" :close-button="($route.params.product) ? $route.params.return : 'Recommendation'" :title="product.name" :label="type.short" :size-content="true">

	<app-title text="How to use an inhaler" :is-uppercase="true" :hide-line="true" />

	<iframe v-if="active === 'first'" class="video" :src="product.video" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
	<iframe v-if="active === 'second'" class="video" :src="product.multiple.video" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>

	<div class="toggle" v-if="product.multiple">
		<div class="toggle-item" :class="{'is-active': active === 'first'}" v-on:click="active = 'first'">{{ product.multiple.first }}</div>
		<div class="toggle-item" :class="{'is-active': active === 'second'}" v-on:click="active = 'second'">{{ product.multiple.second }}</div>
	</div>

	<div class="buttons">
		
		<app-button text="7 steps" theme="purple" v-on:click="onStepsClick" />
		<app-button :text="($route.params.product) ? 'Close' : 'Complete'" theme="purple" v-on:click="onCompleteClick" />
		
	</div>

</app-page>

</template>

<script>

export default {

	data: function() {

		return {
			active: 'first'
		}

	},

	computed: {

		product: function() {

			return (this.$route.params.product) ? this.$route.params.product : this.$store.getters['selection']

		},

		type: function() {

			return (this.$route.params.type) ? this.$route.params.type : this.$store.getters['selection/type']

		}

	},

	methods: {

		onStepsClick: function() {

			if (this.$route.params.product) {

				this.$router.push({
					name: 'Recommendation.Steps',
					params: this.$route.params
				})

			} else {

				this.$router.push({
					name: 'Recommendation.Steps'
				})

			}

		},

		onCompleteClick: function() {

			if (this.$route.params.product) {

				this.$router.push({
					name: this.$route.params.return
				})

			} else {

				this.$store.commit('completed', 'video')

				this.$router.push({
					name: 'Recommendation'
				})

			}

		}

	}

}

</script>

<style scoped>

.video {
	width: 640px;
	height: 360px;
	margin: 0px auto 40px auto;
	display: block;
}

.is-mobile .video {
	width: 320px;
	height: 180px;
}

.buttons {
	display: flex;
	justify-content: center;
}

.buttons >>> .button {
	margin: 0px 10px;
	width: 150px;
}

.toggle {
	height: 40px;
	background-color: #fff;
	border-radius: 24px;
	margin-bottom: 40px;
	padding: 2px;
	display: flex;
	width: 300px;
	margin: -20px auto 40px auto;
}

.toggle-item {
	color: #333;
	width: 50%;
	height: 36px;
	font-size: 14px;
	font-weight: bold;
	line-height: 36px;
	padding: 0px 20px;
	white-space: nowrap;
	text-align: center;
	border-radius: 24px;
	transition: all 100ms linear;
	cursor: pointer;
}

.toggle-item.is-active {
	background-color: #23D6F7;
	color: #fff;
}

.toggle-item:not(.is-active):hover {
	background-color: #eee;
}

</style>
